import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { navigate } from "gatsby";

import AddIcon from "../../images/add.svg";
import DelIcon from "../../images/Close.svg";
import Spinner from "../../images/spinner.svg";
import CheckBlankCircle from "../../images/checkbox-blank-circle-outline.svg";
import CheckCircle from "../../images/check-circle-outline.svg";
import {
  AddPaymentMethodButton,
  ButtonContainer,
  ContaintContainer,
  DeletePaymentMethodButton,
  PageTitle,
  PaymentMethodContainer,
  PaymentMethodImage,
  PaymentMethodNameContainer,
  PaymentMethodText,
  PaymentMethodTextWhite,
  Separator,
  SetDefaultPaymentMethodButton,
  // Wrapper,
} from "./style";

import PaymentMethodIcon from "./payment-method-icon";
import useThunkDispatch from "../../hooks/use-thunk-dispatch";
import { useToast, TOAST_TYPE } from "../../ui/toast";
import getCardNumber from "../../utils/cardNumber";

import { AppState } from "../../store";
import { PaymentMethod } from "../../types/payment-method";
import {
  deletePaymentMethod,
  getPaymentMethods,
  setDefaultPaymentMethod,
} from "../../store/user/actions";
import { BackBtnIcon } from "../profile-back-btn-icon";
import { Title, TitleContainer, Wrapper } from "../orders/style/orders";

const PaymentMethodLine: FC<PaymentMethod & {
  hideSelectDefault?: boolean;
}> = ({ ...paymentMethod }) => {
  const { id, brand, last4, isDefault, hideSelectDefault } = paymentMethod;
  const dispatch = useThunkDispatch();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isDeletion, setIsDeletion] = useState(true);
  const { formatMessage } = useIntl();

  const deleteMethod = useCallback(async () => {
    setIsDeletion(true);
    setLoading(true);

    const response = await dispatch(deletePaymentMethod(id));
    if (!response.ok) {
      showToast(response.error ? response.error.message : "Error", {
        type: TOAST_TYPE.ERROR,
      });
    }

    setLoading(false);
  }, [id]);

  const setDefaultMethod = useCallback(async () => {
    setIsDeletion(false);
    setLoading(true);

    const response = await dispatch(setDefaultPaymentMethod(id));
    if (!response.ok) {
      showToast(response.error ? response.error.message : "Error", {
        type: TOAST_TYPE.ERROR,
      });
    }

    setLoading(false);
  }, [id]);

  return (
    <PaymentMethodContainer>
      {loading ? (
        <>
          {isDeletion ? (
            <FormattedMessage id="payment.delete-method" />
          ) : (
            <FormattedMessage id="payment.set.default.method" />
          )}

          <Spinner />
        </>
      ) : (
        <>
          <PaymentMethodNameContainer>
            <PaymentMethodImage>
              <PaymentMethodIcon brand={brand} />
            </PaymentMethodImage>
            <PaymentMethodText>{getCardNumber(last4)}</PaymentMethodText>
          </PaymentMethodNameContainer>
          <ButtonContainer hideSelectDefault={hideSelectDefault}>
            {!hideSelectDefault && (
              <SetDefaultPaymentMethodButton
                onClick={setDefaultMethod}
                disabled={isDefault}
                title={formatMessage({
                  id: "payment.set.default.method.title",
                })}
              >
                {isDefault ? <CheckCircle /> : <CheckBlankCircle />}
              </SetDefaultPaymentMethodButton>
            )}

            <DeletePaymentMethodButton
              onClick={deleteMethod}
              title={formatMessage({ id: "payment.delete-method.title" })}
            >
              <DelIcon />
            </DeletePaymentMethodButton>
          </ButtonContainer>
        </>
      )}
    </PaymentMethodContainer>
  );
};

const ChoosePaymentMethodModal: FC = () => {
  const token = useSelector<AppState, string>(state => state.auth.token);
  useEffect(() => {
    if (!token) {
      navigate("/signin");
    }
  });

  const dispatch = useThunkDispatch();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(true);
  const paymentMethods = useSelector<AppState, PaymentMethod[]>(
    state => state.user.paymentMethods
  );

  const goToAddPayment = useCallback(
    () => navigate("/add-payment", { state: { redirectUrl: "/profile" } }),
    []
  );

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      setLoading(true);

      const response = await dispatch(getPaymentMethods());
      if (!response.ok) {
        showToast(response.error ? response.error.message : "Error", {
          type: TOAST_TYPE.ERROR,
        });
      }

      setLoading(false);
    };

    fetchData();
  }, [dispatch, showToast]);

  return (
    <Wrapper>
      <TitleContainer>
        <BackBtnIcon />
        <Title>
          <FormattedMessage id="paymentMethods.title" />
        </Title>
      </TitleContainer>
      <ContaintContainer>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {paymentMethods &&
              paymentMethods.map(paymentMethod => (
                <PaymentMethodLine
                  key={paymentMethod.id}
                  {...paymentMethod}
                  hideSelectDefault={paymentMethods.length === 1}
                />
              ))}
            <AddPaymentMethodButton onClick={goToAddPayment}>
              <AddIcon />
              <PaymentMethodTextWhite>
                <FormattedMessage id="profile.add-payment.add" />
              </PaymentMethodTextWhite>
            </AddPaymentMethodButton>
          </>
        )}
      </ContaintContainer>
    </Wrapper>
  );
};

export default ChoosePaymentMethodModal;
