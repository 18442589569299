import styled from "styled-components";

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.grey};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  width: 100%;
`;

export const PageTitle = styled.span`
  margin-bottom: 24px;
  margin-top: 50px;
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme: { colors } }): string => colors.primary};
  display: flex;
  justify-content: center;
  flex: 1;
`;

export const PaymentMethodContainer = styled.div`
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.white};
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  padding: 15px;
  width: 100%;

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    width: 500px;
  }
`;

export const PaymentMethodNameContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const PaymentMethodImage = styled.div`
  height: 24px;
  margin-bottom: 0;
  margin-right: 20px;
  width: 24px;
`;

export const PaymentMethodText = styled.span`
  font-family: ${({ theme: { fonts } }): string => fonts.main};
  font-size: 14px;
  margin-left: 4px;
`;

export const PaymentMethodTextWhite = styled(PaymentMethodText)`
  color: ${({ theme: { colors } }): string => colors.white};
`;

export const Separator = styled.div`
  height: 3px;
  background-color: ${({ theme: { colors } }): string => colors.lightGrey};
`;

export const ButtonContainer = styled.div<{ hideSelectDefault?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: ${({ hideSelectDefault }): string =>
    hideSelectDefault ? "0" : "75px"};
`;

export const ContaintContainer = styled.div`
  padding: 36px 0;
`;

export const DeletePaymentMethodButton = styled.button`
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.white};
  border: none;
  display: flex;
  cursor: pointer;
`;

export const SetDefaultPaymentMethodButton = styled.button`
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.white};
  border: none;
  display: flex;
  cursor: pointer;
`;

export const AddPaymentMethodButton = styled.button`
  align-items: center;
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  border: none;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 30px;
  padding: 15px;
  width: 100%;
  cursor: pointer;

  svg > path {
    fill: ${({ theme: { colors } }): string => colors.white};
  }

  @media ${({ theme: { breakpoints } }): string => breakpoints.desktop} {
    margin: auto;
    margin-bottom: 50px;
    margin-top: 30px;
    width: 400px;
  }
`;
