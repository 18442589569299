import React, { FC } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PaymentMethods from "../components/payment-methods";

const IndexPage: FC = () => (
  <Layout header requireAuth>
    <SEO title="Accueil" />
    <PaymentMethods />
  </Layout>
);

export default IndexPage;
